import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import { Dialog, Button, IconInfoCircle, IconQuestionCircle, Link, TextArea, TextInput } from 'hds-react';
import ExternalLink from '../../../components/ExternalLink';
import Playground from '../../../components/Playground';
import TabsLayout from './tabs.mdx';
export const _frontmatter = {
  "slug": "/components/dialog/code",
  "title": "Dialog - Code"
};
const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};
const IconCheckCircleFill = makeShortcode("IconCheckCircleFill");
const IconCrossCircle = makeShortcode("IconCrossCircle");
const layoutProps = {
  _frontmatter
};
const MDXLayout = ({
  children,
  pageContext
}) => <TabsLayout pageContext={pageContext}>{children}</TabsLayout>;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h2 {...{
      "id": "code",
      "style": {
        "position": "relative"
      }
    }}>{`Code`}<a parentName="h2" {...{
        "href": "#code",
        "aria-label": "code permalink",
        "className": "header-anchor after"
      }}><span parentName="a" {...{
          "className": "hds-anchor-icon hds-icon hds-icon--link hds-icon--size-xs",
          "aria-hidden": "true",
          "style": {
            "verticalAlign": "middle"
          }
        }}></span></a></h2>
    <h3 {...{
      "id": "code-examples",
      "style": {
        "position": "relative"
      }
    }}>{`Code examples`}<a parentName="h3" {...{
        "href": "#code-examples",
        "aria-label": "code examples permalink",
        "className": "header-anchor after"
      }}><span parentName="a" {...{
          "className": "hds-anchor-icon hds-icon hds-icon--link hds-icon--size-xs",
          "aria-hidden": "true",
          "style": {
            "verticalAlign": "middle"
          }
        }}></span></a></h3>
    <h4 {...{
      "id": "info-dialog",
      "style": {
        "position": "relative"
      }
    }}>{`Info dialog`}<a parentName="h4" {...{
        "href": "#info-dialog",
        "aria-label": "info dialog permalink",
        "className": "header-anchor after"
      }}><span parentName="a" {...{
          "className": "hds-anchor-icon hds-icon hds-icon--link hds-icon--size-xs",
          "aria-hidden": "true",
          "style": {
            "verticalAlign": "middle"
          }
        }}></span></a></h4>
    <Playground mdxType="Playground">
      <pre><code parentName="pre" {...{
          "className": "language-jsx"
        }}>{`{() => {
  const dialogTargetRef = React.useRef(null); // We need to render the dialog into a div inside the Playground component to ensure correct dialog styles in the HDS documentation. The dialog will be rendered into the document body by default.
  const openInfoDialogButtonRef = React.useRef(null);
  const [isOpen, setIsOpen] = React.useState(false);
  const close = () => setIsOpen(false);
  const titleId = "info-dialog-title";
  const descriptionId = "info-dialog-content";
  return (
    <>
      <div ref={dialogTargetRef}/>
      <Button ref={openInfoDialogButtonRef} onClick={() => setIsOpen(true)}>
        Open Info dialog
      </Button>
      <Dialog
        id="info-dialog"
        aria-labelledby={titleId}
        aria-describedby={descriptionId}
        isOpen={isOpen}
        close={close}
        closeButtonLabelText="Close info dialog"
        focusAfterCloseRef={openInfoDialogButtonRef}
        targetElement={dialogTargetRef.current}
      >
        <Dialog.Header
          id={titleId}
          title="Terms of service have changed"
          iconLeft={<IconInfoCircle aria-hidden="true" />}
        />
        <Dialog.Content>
          <p id={descriptionId} className="text-body">
            Please note that the terms of this service have changed. You can review the changes in the user settings.</p>
        </Dialog.Content>
        <Dialog.ActionButtons>
          <Button onClick={close}>
            Close
          </Button>
        </Dialog.ActionButtons>
      </Dialog>
    </>
  )
}}
`}</code></pre>
    </Playground>
    <h3 {...{
      "id": "confirm-dialog",
      "style": {
        "position": "relative"
      }
    }}>{`Confirm dialog`}<a parentName="h3" {...{
        "href": "#confirm-dialog",
        "aria-label": "confirm dialog permalink",
        "className": "header-anchor after"
      }}><span parentName="a" {...{
          "className": "hds-anchor-icon hds-icon hds-icon--link hds-icon--size-xs",
          "aria-hidden": "true",
          "style": {
            "verticalAlign": "middle"
          }
        }}></span></a></h3>
    <Playground mdxType="Playground">
      <pre><code parentName="pre" {...{
          "className": "language-jsx"
        }}>{`{() => {
  const confirmationDialogTarget = React.useRef(null); // We need to render the dialog into a div inside the Playground component to ensure correct dialog styles in the HDS documentation. The dialog will be rendered into the document body by default.
  const openConfirmationDialogButtonRef = React.useRef(null);
  const [isOpen, setIsOpen] = React.useState(false);
  const close = () => setIsOpen(false);
  const titleId = "confirmation-dialog-title";
  const descriptionId = "confirmation-dialog-info";
  return (
    <>
      <div ref={confirmationDialogTarget}/>
      <Button ref={openConfirmationDialogButtonRef} onClick={() => setIsOpen(true)}>
        Open Confirmation dialog
      </Button>
      <Dialog
        id="confirmation-dialog"
        aria-labelledby={titleId}
        aria-describedby={descriptionId}
        isOpen={isOpen}
        focusAfterCloseRef={openConfirmationDialogButtonRef}
        targetElement={confirmationDialogTarget.current}
      >
        <Dialog.Header
          id={titleId}
          title="Are you sure you want to continue?"
          iconLeft={<IconQuestionCircle aria-hidden="true" />}
        />
        <Dialog.Content>
          <p id={descriptionId} className="text-body">
            You have not filled all form fields. Do you still want to continue? You can later return to edit this form. Saved forms can be accessed in your personal profile.
          </p>
        </Dialog.Content>
        <Dialog.ActionButtons>
          <Button onClick={close}>
            Continue
          </Button>
          <Button onClick={close} variant="secondary">
            Cancel
          </Button>
        </Dialog.ActionButtons>
      </Dialog>
    </>
  )
}}
`}</code></pre>
    </Playground>
    <h3 {...{
      "id": "danger-dialog",
      "style": {
        "position": "relative"
      }
    }}>{`Danger dialog`}<a parentName="h3" {...{
        "href": "#danger-dialog",
        "aria-label": "danger dialog permalink",
        "className": "header-anchor after"
      }}><span parentName="a" {...{
          "className": "hds-anchor-icon hds-icon hds-icon--link hds-icon--size-xs",
          "aria-hidden": "true",
          "style": {
            "verticalAlign": "middle"
          }
        }}></span></a></h3>
    <Playground mdxType="Playground">
      <pre><code parentName="pre" {...{
          "className": "language-jsx"
        }}>{`{() => {
  const dangerDialogTargetRef = React.useRef(null); // We need to render the dialog into a div inside the Playground component to ensure correct dialog styles in the HDS documentation. The dialog will be rendered into the document body by default.
  const openDeleteDialogButtonRef = React.useRef(null);
  const [isOpen, setIsOpen] = React.useState(false);
  const close = () => setIsOpen(false);
  const titleId = "delete-dialog-title";
  const descriptionId = "delete-dialog-info";
  return (
    <>
      <div ref={dangerDialogTargetRef}/>
      <Button ref={openDeleteDialogButtonRef} onClick={() => setIsOpen(true)}>
        Open Delete dialog
      </Button>
      <Dialog
        variant="danger"
        id="delete-dialog"
        aria-labelledby={titleId}
        aria-describedby={descriptionId}
        isOpen={isOpen}
        focusAfterCloseRef={openDeleteDialogButtonRef}
        targetElement={dangerDialogTargetRef.current}
      >
        <Dialog.Header
          id={titleId}
          title="Are you sure you want to delete this blog post?"
          iconLeft={<IconAlertCircle aria-hidden="true" />}
        />
        <Dialog.Content>
          <p id={descriptionId} className="text-body">
            The blog post will be deleted immediately. Deletion is permanent and it cannot be reverted.
          </p>
        </Dialog.Content>
        <Dialog.ActionButtons>
          <Button onClick={close} theme="black" variant="secondary">
            Cancel
          </Button>
          <Button
            variant="danger"
            iconLeft={<IconTrash aria-hidden="true" />}
            onClick={() => {
              // Add confirm operations here
              close();
            }}
          >
            Delete the blog post
          </Button>
        </Dialog.ActionButtons>
      </Dialog>
    </>
  )
}}
`}</code></pre>
    </Playground>
    <h3 {...{
      "id": "scrollable-dialog",
      "style": {
        "position": "relative"
      }
    }}>{`Scrollable dialog`}<a parentName="h3" {...{
        "href": "#scrollable-dialog",
        "aria-label": "scrollable dialog permalink",
        "className": "header-anchor after"
      }}><span parentName="a" {...{
          "className": "hds-anchor-icon hds-icon hds-icon--link hds-icon--size-xs",
          "aria-hidden": "true",
          "style": {
            "verticalAlign": "middle"
          }
        }}></span></a></h3>
    <Playground mdxType="Playground">
      <pre><code parentName="pre" {...{
          "className": "language-jsx"
        }}>{`{() => {
  const termsDialogTargetRef = React.useRef(null); // We need to render the dialog into a div inside the Playground component to ensure correct dialog styles in the HDS documentation. The dialog will be rendered into the document body by default.
  const openTermsDialogButtonRef = React.useRef(null);
  const [isOpen, setIsOpen] = React.useState(false);
  const close = () => setIsOpen(false);
  const titleId = "terms-dialog-title";
  const descriptionId = "terms-dialog-info";
  return (
    <>
      <div ref={termsDialogTargetRef}/>
      <Button ref={openTermsDialogButtonRef} onClick={() => setIsOpen(true)}>
        Open Terms dialog
      </Button>
      <Dialog
        id="terms-dialog"
        aria-labelledby={titleId}
        aria-describedby={descriptionId}
        isOpen={isOpen}
        focusAfterCloseRef={openTermsDialogButtonRef}
        targetElement={termsDialogTargetRef.current}
        scrollable
      >
        <Dialog.Header
          id={titleId}
          title="Do you accept the terms of service?"
          iconLeft={<IconAlertCircle aria-hidden="true" />}
        />
        <Dialog.Content>
          <h3 id={descriptionId}>Terms of service</h3>
          <p className="text-body">
            Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium, totam rem
            aperiam, eaque ipsa quae ab illo inventore veritatis et quasi architecto beatae vitae dicta sunt explicabo.
            Nemo enim ipsam voluptatem quia voluptas sit aspernatur aut odit aut fugit, sed quia consequuntur magni
            dolores eos qui ratione voluptatem sequi nesciunt. Neque porro quisquam est, qui dolorem ipsum quia dolor
            sit amet, consectetur, adipisci velit, sed quia non numquam eius modi tempora incidunt ut labore et dolore
            magnam aliquam quaerat voluptatem. Ut enim ad minima veniam, quis nostrum exercitationem ullam corporis
            suscipit laboriosam, nisi ut aliquid ex ea commodi consequatur? Quis autem vel eum iure reprehenderit qui in
            ea voluptate velit esse quam nihil molestiae consequatur, vel illum qui dolorem eum fugiat quo voluptas
            nulla pariatur?
          </p>
          <p className="text-body">
            Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium, totam rem
            aperiam, eaque ipsa quae ab illo inventore veritatis et quasi architecto beatae vitae dicta sunt explicabo.
            Nemo enim ipsam voluptatem quia voluptas sit aspernatur aut odit aut fugit, sed quia consequuntur magni
            dolores eos qui ratione voluptatem sequi nesciunt. Neque porro quisquam est, qui dolorem ipsum quia dolor
            sit amet, consectetur, adipisci velit, sed quia non numquam eius modi tempora incidunt ut labore et dolore
            magnam aliquam quaerat voluptatem. Ut enim ad minima veniam, quis nostrum exercitationem ullam corporis
            suscipit laboriosam, nisi ut aliquid ex ea commodi consequatur? Quis autem vel eum iure reprehenderit qui in
            ea voluptate velit esse quam nihil molestiae consequatur, vel illum qui dolorem eum fugiat quo voluptas
            nulla pariatur?
          </p>
          <p className="text-body">
            Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium, totam rem
            aperiam, eaque ipsa quae ab illo inventore veritatis et quasi architecto beatae vitae dicta sunt explicabo.
            Nemo enim ipsam voluptatem quia voluptas sit aspernatur aut odit aut fugit, sed quia consequuntur magni
            dolores eos qui ratione voluptatem sequi nesciunt. Neque porro quisquam est, qui dolorem ipsum quia dolor
            sit amet, consectetur, adipisci velit, sed quia non numquam eius modi tempora incidunt ut labore et dolore
            magnam aliquam quaerat voluptatem. Ut enim ad minima veniam, quis nostrum exercitationem ullam corporis
            suscipit laboriosam, nisi ut aliquid ex ea commodi consequatur? Quis autem vel eum iure reprehenderit qui in
            ea voluptate velit esse quam nihil molestiae consequatur, vel illum qui dolorem eum fugiat quo voluptas
            nulla pariatur?
          </p>
          <p className="text-body">
            Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium, totam rem
            aperiam, eaque ipsa quae ab illo inventore veritatis et quasi architecto beatae vitae dicta sunt explicabo.
            Nemo enim ipsam voluptatem quia voluptas sit aspernatur aut odit aut fugit, sed quia consequuntur magni
            dolores eos qui ratione voluptatem sequi nesciunt. Neque porro quisquam est, qui dolorem ipsum quia dolor
            sit amet, consectetur, adipisci velit, sed quia non numquam eius modi tempora incidunt ut labore et dolore
            magnam aliquam quaerat voluptatem. Ut enim ad minima veniam, quis nostrum exercitationem ullam corporis
            suscipit laboriosam, nisi ut aliquid ex ea commodi consequatur? Quis autem vel eum iure reprehenderit qui in
            ea voluptate velit esse quam nihil molestiae consequatur, vel illum qui dolorem eum fugiat quo voluptas
            nulla pariatur?
          </p>
          <p className="text-body">
            Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium, totam rem
            aperiam, eaque ipsa quae ab illo inventore veritatis et quasi architecto beatae vitae dicta sunt explicabo.
            Nemo enim ipsam voluptatem quia voluptas sit aspernatur aut odit aut fugit, sed quia consequuntur magni
            dolores eos qui ratione voluptatem sequi nesciunt. Neque porro quisquam est, qui dolorem ipsum quia dolor
            sit amet, consectetur, adipisci velit, sed quia non numquam eius modi tempora incidunt ut labore et dolore
            magnam aliquam quaerat voluptatem. Ut enim ad minima veniam, quis nostrum exercitationem ullam corporis
            suscipit laboriosam, nisi ut aliquid ex ea commodi consequatur? Quis autem vel eum iure reprehenderit qui in
            ea voluptate velit esse quam nihil molestiae consequatur, vel illum qui dolorem eum fugiat quo voluptas
            nulla pariatur?
          </p>
        </Dialog.Content>
        <Dialog.ActionButtons>
          <Button onClick={close}>
            Accept terms
          </Button>
          <Button onClick={close} variant="secondary">
            Cancel
          </Button>
        </Dialog.ActionButtons>
      </Dialog>
    </>
  )
}}
`}</code></pre>
    </Playground>
    <h4 {...{
      "id": "dialog-with-custom-content",
      "style": {
        "position": "relative"
      }
    }}>{`Dialog with custom content`}<a parentName="h4" {...{
        "href": "#dialog-with-custom-content",
        "aria-label": "dialog with custom content permalink",
        "className": "header-anchor after"
      }}><span parentName="a" {...{
          "className": "hds-anchor-icon hds-icon hds-icon--link hds-icon--size-xs",
          "aria-hidden": "true",
          "style": {
            "verticalAlign": "middle"
          }
        }}></span></a></h4>
    <Playground mdxType="Playground">
      <pre><code parentName="pre" {...{
          "className": "language-jsx"
        }}>{`{() => {
  const customDialogTargetRef = React.useRef(null); // We need to render the dialog into a div inside the Playground component to ensure correct dialog styles in the HDS documentation. The dialog will be rendered into the document body by default.
  const openCustomDialogButtonRef = React.useRef(null);
  const [isOpen, setIsOpen] = React.useState(false);
  const close = () => setIsOpen(false);
  const titleId = "custom-dialog-title";
  const descriptionId = "custom-dialog-info";
  return (
    <>
      <div ref={customDialogTargetRef}/>
      <Button ref={openCustomDialogButtonRef} onClick={() => setIsOpen(true)}>
        Open Dialog with custom content
      </Button>
      <Dialog
        id="custom-dialog"
        aria-labelledby={titleId}
        aria-describedby={descriptionId}
        isOpen={isOpen}
        focusAfterCloseRef={openCustomDialogButtonRef}
        targetElement={customDialogTargetRef.current}
      >
      <Dialog.Header
        id={titleId}
        title="Add new item"
      />
      <Dialog.Content>
        <p id={descriptionId} className="text-body">
          Add a new item by filling the information below. All fields are mandatory.
        </p>
        <TextInput
          id="item-name"
          label="Item name"
          placeholder="E.g. Item 1"
          helperText="Item's name must be unique."
          required
        />
        <br />
        <TextArea
          id="item-description"
          label="Item description"
          placeholder="E.g. Item 1 is the first item of the system."
          required
        />
      </Dialog.Content>
      <Dialog.ActionButtons>
        <Button
          onClick={() => {
            // Add operations here
            close();
          }}
        >
          Add item
        </Button>
        <Button onClick={close} variant="secondary">
          Cancel
        </Button>
      </Dialog.ActionButtons>
    </Dialog>
    </>
  )
}}
`}</code></pre>
    </Playground>
    <h3 {...{
      "id": "packages",
      "style": {
        "position": "relative"
      }
    }}>{`Packages`}<a parentName="h3" {...{
        "href": "#packages",
        "aria-label": "packages permalink",
        "className": "header-anchor after"
      }}><span parentName="a" {...{
          "className": "hds-anchor-icon hds-icon hds-icon--link hds-icon--size-xs",
          "aria-hidden": "true",
          "style": {
            "verticalAlign": "middle"
          }
        }}></span></a></h3>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Package`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Included`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Storybook link`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Source link`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><strong parentName="td">{`HDS React`}</strong></td>
          <td parentName="tr" {...{
            "align": null
          }}><div style={{
              display: 'flex',
              gap: 'var(--spacing-3-xs)'
            }}><IconCheckCircleFill mdxType="IconCheckCircleFill" />{` Yes `}</div></td>
          <td parentName="tr" {...{
            "align": null
          }}><Link openInNewTab openInNewTabAriaLabel="Opens in a new tab" href="/storybook/react/?path=/story/components-dialog--default" mdxType="Link">{`View in Storybook`}</Link></td>
          <td parentName="tr" {...{
            "align": null
          }}><ExternalLink openInNewTab openInNewTabAriaLabel="Opens in a new tab" href="https://github.com/City-of-Helsinki/helsinki-design-system/blob/master/packages/react/src/components/dialog/Dialog.tsx" mdxType="ExternalLink">{`View source`}</ExternalLink></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><strong parentName="td">{`HDS Core`}</strong></td>
          <td parentName="tr" {...{
            "align": null
          }}><div style={{
              display: 'flex',
              gap: 'var(--spacing-3-xs)'
            }}><IconCrossCircle mdxType="IconCrossCircle" />{` No `}</div></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`-`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`-`}</td>
        </tr>
      </tbody>
    </table>
    <h3 {...{
      "id": "properties",
      "style": {
        "position": "relative"
      }
    }}>{`Properties`}<a parentName="h3" {...{
        "href": "#properties",
        "aria-label": "properties permalink",
        "className": "header-anchor after"
      }}><span parentName="a" {...{
          "className": "hds-anchor-icon hds-icon hds-icon--link hds-icon--size-xs",
          "aria-hidden": "true",
          "style": {
            "verticalAlign": "middle"
          }
        }}></span></a></h3>
    <p>{`Note! You can find the full list of properties in the `}<Link openInNewTab openInNewTabAriaLabel="Opens in a new tab" href="/storybook/react/?path=/story/components-dialog--default" mdxType="Link">{`React Storybook.`}</Link></p>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Property`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Description`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Values`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Default value`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`isOpen`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`If set to true, the dialog is visible.`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`boolean`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`false`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`focusAfterCloseRef`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`The element ref which will get focus after the dialog is closed.`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`RefObject`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`-`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`focusAfterCloseElement`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`The element which will get focus after the dialog is closed.`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`Object`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`-`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`scrollable`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`If set to true, the dialog content is scrollable if needed. Use only for long text contents.`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`boolean`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`false`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`variant`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Defines the dialog variant.`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`primary, danger`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`primary`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`close`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`A function called when a dialog close button is pressed. The button if shown if this property exists.`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`function`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`-`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`closeButtonLabelText`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`The aria-label for the close button. Required with `}<inlineCode parentName="td">{`close`}</inlineCode>{` property.`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`string`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`-`}</td>
        </tr>
      </tbody>
    </table>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      